@import "./var";
input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
}
.ant-input-affix-wrapper > input.ant-input:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper-has-feedback {
  border: 1px solid $gray-300;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
  font-family: $font-family-text-normal;
  .ant-input {
    border-radius: 0;
    box-shadow: none;
  }
  .ant-input-suffix {
    .ant-form-item-feedback-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      &.ant-form-item-feedback-icon-success {
        display: none;
      }
      &.ant-form-item-feedback-icon-error {
        .anticon {
          height: 16px;
          width: 16px;
          background: url("../assets/images/error-info.svg") center no-repeat;
          background-size: 100%;
          svg {
            display: none;
          }
        }
      }
      .anticon {
        vertical-align: middle;
      }
    }
  }
}

.ant-input-lg,
.ant-input-affix-wrapper-lg {
  padding: 8.5px 11px;
}

.ant-form-large .ant-form-item-label > label {
  height: 15px;
}

.ant-form-item-label > label {
  font-family: $font-family-text-medium;
}

.ant-btn-lg {
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.ant-btn-primary {
  background: $medium-blue-600;
  border: 1px solid $medium-blue-600;
}

.ant-btn-default {
  border: 1px solid $gray-300;
}

.ant-steps-item-tail::after {
  height: 2px;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $medium-blue-600;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $gray-200;
}

.ant-steps-small .ant-steps-item-title {
  font-family: $font-family-text-medium;
  font-size: $font-text-sm;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 130px;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $gray-700;
}

.ant-steps-item-description {
  font-family: $font-family-text-medium;
}

// .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description, .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
//   color: $gray-500;
// }

// .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
//   color: $medium-blue-600;
// }

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 57px;
  padding: 3.5px 9px;
}

.ant-form-item-has-error .ant-form-item-label > label {
  color: #ff4d4f;
}

::placeholder {
  color: $gray-500 !important;
  font-size: 16px;
}

.ant-form-item-with-help .ant-form-item-explain {
  margin-top: 6px;
  font-size: 14px;
  color: $gray-500;
}

.ant-checkbox + span {
  font-family: $font-family-text-medium;
  color: $gray-700;
}
.ant-form-item {
  margin-bottom: 36px;
}
// .required-field::after {
//   content: "*";
//   color: red;
//   margin-left: 2px;
// }

// .required-field-before::before {
//   content: "*";
//   color: red;
//   margin-left: 2px;
// }

// input[type="text"] {
//   border: none;
//   border-radius: 0;
//   border-bottom: 1px solid #ced4da;
// }

.form-select {
  // &.custom-select {
  //   background: #fff url("../assets/images/arrow_drop_down.svg") right 0.75rem center/10px 10px
  //     no-repeat;
  //   border: none;
  //   border-radius: 0;
  //   border-bottom: 1px solid #ced4da;
  // }
  &.rounded-select {
    border-radius: 6px;
  }
}

.btn.btn-primary {
  background-color: #2a6f97;
  border: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 12px 18px;
  font-weight: 600;
  &:hover {
    background-color: #14557a;
  }
  &:focus {
    background-color: #14557a;
  }
  &:active {
    background-color: #14557a;
  }
  &:disabled {
    background-color: #2a6f97;
  }
}

// .btn.btn-light {
//   color: $secondary-color;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
//   border: 0;
//   border-radius: 8px;
//   padding: 12px 18px;
//   font-weight: 600;
//   &:hover {
//     background-color: #d8eefe;
//     color: #0062a8;
//   }
//   &:focus {
//     background-color: #d8eefe;
//     color: #0062a8;
//   }
//   &:active {
//     background-color: #d8eefe;
//     color: #0062a8;
//   }
// }

.cursor-pointer {
  cursor: pointer;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-color-grey {
  color: #666666;
}

// .text-color-black {
//   color: $primary-color;
// }

// .text-secondary-black {
//   color: $primary-black-color;
// }
