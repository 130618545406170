@import "../../../theme-style/var";
.btn-custom {
  font-family: $font-family-text-medium;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-family: inherit;
  }
}
.btn-icon {
  padding-right: 10px;
}
