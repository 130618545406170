@font-face {
  font-family: "Avenir-Light";
  src: url("/assets/fonts/Avenir-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir-Regular";
  src: url("/assets/fonts/Avenir-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Medium";
  src: url("/assets/fonts/Avenir-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Semibold";
  src: url("/assets/fonts/Avenir-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir-Bold";
  src: url("/assets/fonts/Avenir-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("/assets/fonts/Lato-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-Medium";
  src: url("/assets/fonts/Lato-Medium.ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-Semibold";
  src: url("/assets/fonts/Lato-Semibold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-Bold";
  src: url("/assets/fonts/Lato-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "Lato-Regular";
}
