
@import "../../theme-style/var";
.account-setup-body {
    margin-top: 100px;
    .account-setup-heading {
        margin-bottom: 12px;
        color: $gray-900;
        font-family: $font-family-display-semibold;
        font-size: $font-display-sm;
    }
    .account-setup-sub-heading {
        margin-bottom: 32px;
        color: $gray-500;
        font-family: $font-family-text-normal;
        font-size: $font-text-md;
    }
    .submit-btn {
        margin-top: 0px;
    }
    .steps-outer {
        width: 60%;
        margin-top: 30px;
    }
  }
 