$breakpoint-992-above: 992px;
$breakpoint-991-below: 991px;
$breakpoint-767-below: 767px;


//colors
$medium-blue-600: #2165B0;
$medium-blue-700: #123862;
$medium-blue-600: #2165B0;

$gray-100: #F2F2F2 !default;
$gray-200: #EAECF0;
$gray-300: #D0D5DD;
$gray-700: #344054;
$gray-900: #101828;
$gray-500: #667085;
$gray-50: #F9FAFB;


//fonts
$font-family-display-regular: 'Avenir-Light';
$font-family-display-medium: 'Avenir-Medium';
$font-family-display-semibold: 'Avenir-Semibold';
$font-family-display-bold: 'Avenir-Bold';

$font-family-text-normal: 'Lato-Regular', sans-serif;;
$font-family-text-medium: 'Lato-Medium', sans-serif;;
$font-family-text-semibold: 'Lato-Semibold', sans-serif;;
$font-family-text-bold: 'Lato-Bold', sans-serif;;

$font-size-base: 1rem !default;
$font-display-2xl: 72px;
$font-display-xl: 60px;
$font-display-lg: 48px;
$font-display-md: 36px;
$font-display-sm: 30px;
$font-display-xs: 24px;
$font-text-xl: 20px;
$font-text-lg: 18px;
$font-text-md: 16px;
$font-text-sm: 14px;
$font-text-xs: 12px;


$font-size-08 : 08px;
$font-size-09 : 09px;
$font-size-10 : 10px;
$font-size-12 : 12px;
$font-size-13 : 13px;
$font-size-14 : 14px;
$font-size-16 : 16px;
$font-size-18 : 18px;
$font-size-20 : 20px;
$font-size-22 : 22px;
$font-size-24 : 24px;
$font-size-28 : 28px;
$font-size-30 : 30px;
$font-size-32 : 32px;
$font-size-34 : 34px;
$font-size-36 : 36px;
$font-size-40 : 40px;
$font-size-48 : 48px;
$font-size-70 : 70px;
$font-size-85 : 85px;
$font-size-100 : 100px;
$font-size-170 : 170px;




