@import "./font";
@import "./var";
@import "./common";
@import "~bootstrap/scss/bootstrap";
@import "~antd/dist/antd.css";

html {
  overflow-x: hidden;
  min-height: 100vh;
}
body {
  //font-family: $primary-font-family;
  //color: $primary-grey-color;
  overflow-x: hidden;
  font-size: $font-size-base;
  min-height: 100vh;
}
// a,
// a:hover,
// a:visited,
// a:active {
//   color: $secondary-color;
// }
// @media screen and (max-width: $breakpoint-md) {
//   body {
//     font-size: $primary-mobile-font-size;
//   }
// }
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.flex-1 {
  flex: 1;
}
