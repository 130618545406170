@import "../../theme-style/var";
.verify-email-body {
    margin-top: 100px;
    .email-icon {
        margin-bottom: 24px;
    }
    .verify-heading {
        margin-bottom: 12px;
        font-family: $font-family-display-semibold;
        font-size: $font-display-sm;
        color: $gray-900;
    }
    .verify-content {
        margin-bottom: 12px;
        color: $gray-500;
        font-family: $font-family-text-medium;
        font-size: $font-text-md;
    }
    .verify-supporttext {
        font-family: $font-family-text-normal;
        font-size: $font-text-sm;
        margin-bottom: 12px;
        color: $gray-500;
    }
    .verify-support-timer {
        color: $medium-blue-700;
        font-family: $font-family-text-medium;
    }
    .steps-outer {
        width: 60%;
        margin-top: 16%;
    }
  }
 