@import '../../theme-style/var';
.website-header{
	margin : 20px 32px;
}
.header-right {
	color: $gray-500;
	font-family: $font-family-text-normal;
    font-size: $font-text-md;
}
.header-link {
	color: $medium-blue-700;
	font-family: $font-family-text-medium;
}
